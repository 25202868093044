import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const REACT_APP_MOBILE_URL = process.env.REACT_APP_MOBILE_URL;
export const REACT_APP_GUEST_TOKEN = process.env.REACT_APP_GUEST_TOKEN;

export const getLeafNumber = async (query: string, token: string) => {
  return axios.get(`${BASE_URL}/laSoTuVi?${query}`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token !== '' ? token : REACT_APP_GUEST_TOKEN}`,
    },
  });
};

export const resetPassword = async (query: string, body: any) => {
  return axios.post(`${BASE_URL}/auth/reset-password?${query}`, body);
};

export const requestComment = async (body: any) => {
  return await axios.post(`${REACT_APP_MOBILE_URL}/orders/guest`, body, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${REACT_APP_GUEST_TOKEN}`,
    },
  });
};
