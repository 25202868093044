import React, { createRef, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import _ from 'lodash';

import type { RootState } from 'store';
import useOnScreen from 'utils';
import { updateInfo, updateLeafNumberInfo } from 'services/slices/leafNumber';
import { BASE_URL, REACT_APP_GUEST_TOKEN, requestComment } from 'api';
import queryString from 'query-string';

interface PhuTinh {
  ten: string;
  acronym: string;
  level: number;
  color: string;
  fontWeight: string;
}

const LeafNumber = ({ scaleValue }: { scaleValue: number }) => {
  const dispatch = useDispatch();
  const { leafNumber, info } = useSelector(
    (state: RootState) => state.leafNumberReducer
  );

  // const refCanvasTest = createRef<any>();
  const refTable = createRef<HTMLTableElement>();
  const refCanvas = useRef<any>();
  let ctxCanvas: any = null;
  // const isVisibleCanvasTest = useOnScreen(refCanvasTest);
  const [openModal, setOpenModal] = useState(false);
  const [phone, setPhone] = useState('');
  const [notes, setNotes] = useState('');

  useEffect(() => {
    if (!refCanvas?.current) return;
    const canvasEle = refCanvas.current;
    ctxCanvas = canvasEle.getContext('2d');
    ctxCanvas.clearRect(0, 0, canvasEle.width, canvasEle.height);
  }, [refCanvas?.current, leafNumber]);

  const handleRequestComment = async () => {
    if (phone.trim() === '') {
      return alert('Số điện thoại không được để trống!');
    } else if (!/0[1-9][0-9]{8}/.test(phone)) {
      return alert('Vui lòng nhập đúng định dạng số điện thoại!');
    }
    try {
      const response = await requestComment({
        name: info?.ten,
        phone: phone,
        notes: notes,
        infos: {
          gioiTinh: info?.gioiTinh,
          ngaySinh: info?.ngaySinh,
          thangSinh: info?.thangSinh,
          namSinh: info?.namSinh,
          gioSinh: info?.gioSinh,
          phutSinh: info?.phutSinh,
          lichAmDuong: info?.lichAmDuong,
          thangLap: info?.thangLap,
          namXem: info?.namXem,
        },
      });
      if (!!response.data) {
        setOpenModal(false);
        alert('Gửi yêu cầu bình giải thành công!');
      }
    } catch (error) {
      console.log('error: ', error);
    }
  };

  const downloadImage = (blob: any, fileName: string) => {
    const fakeLink: any = window.document.createElement('a');
    fakeLink.style = 'display:none;';
    fakeLink.download = fileName;

    fakeLink.href = blob;

    document.body.appendChild(fakeLink);
    fakeLink.click();
    document.body.removeChild(fakeLink);

    fakeLink.remove();
  };

  function base64ArrayBuffer(arrayBuffer: any) {
    var base64 = '';
    var encodings =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

    var bytes = new Uint8Array(arrayBuffer);
    var byteLength = bytes.byteLength;
    var byteRemainder = byteLength % 3;
    var mainLength = byteLength - byteRemainder;

    var a, b, c, d;
    var chunk;

    // Main loop deals with bytes in chunks of 3
    for (var i = 0; i < mainLength; i = i + 3) {
      // Combine the three bytes into a single integer
      chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

      // Use bitmasks to extract 6-bit segments from the triplet
      a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
      b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
      c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
      d = chunk & 63; // 63       = 2^6 - 1

      // Convert the raw binary segments to the appropriate ASCII encoding
      base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
    }

    // Deal with the remaining bytes and padding
    if (byteRemainder == 1) {
      chunk = bytes[mainLength];

      a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

      // Set the 4 least significant bits to zero
      b = (chunk & 3) << 4; // 3   = 2^2 - 1

      base64 += encodings[a] + encodings[b] + '==';
    } else if (byteRemainder == 2) {
      chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

      a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
      b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

      // Set the 2 least significant bits to zero
      c = (chunk & 15) << 2; // 15    = 2^4 - 1

      base64 += encodings[a] + encodings[b] + encodings[c] + '=';
    }

    return base64;
  }

  function fetchWithAuthentication(url: string, authToken: string) {
    const headers = new Headers();
    headers.set('Authorization', `Bearer ${authToken}`);
    return fetch(url, { headers });
  }

  async function displayProtectedImage(
    imageId: string,
    imageUrl: string,
    authToken: string
  ) {
    // Fetch the image.
    const response = await fetchWithAuthentication(imageUrl, authToken);

    // Convert the data to Base64 and build a data URL.
    const binaryData = await response.arrayBuffer();
    const bytes = new Uint8Array(binaryData);
    const base64 = base64ArrayBuffer(bytes);
    const dataUrl = 'data:image/jpeg;base64,' + base64;
    // Update the source of the image.
    const imageElement = document.getElementById(
      imageId
    ) as HTMLImageElement | null;
    if (!imageElement) return;
    imageElement.src = dataUrl;
  }

  useEffect(() => {
    displayProtectedImage(
      'img-leaflet',
      `${BASE_URL}/laSoTuVi/download?${queryString.stringify(info || {})}`,
      REACT_APP_GUEST_TOKEN || ''
    );
  }, []);

  if (_.isEmpty(leafNumber)) return null;

  return (
    <React.Fragment>
      <div className="d-flex justify-content-center mt-1">
        <div
          style={{
            width: 500,
          }}
          className=""
        >
          <span style={{ fontSize: 16, fontWeight: '900', marginRight: 50 }}>
            Lá số tử vi của bạn
          </span>
        </div>
      </div>
      {/* <div>
        <canvas
          ref={refCanvasTest}
          width="550px"
          height="350px"
          id="canvas-test"
        ></canvas>
      </div> */}
      <div
        id="parent-leafnumber"
        style={{ height: scaleValue * 970 }}
        className="d-flex position-relative mt-1"
      >
        <img
          className="position-absolute"
          style={{
            transform: `scale(${scaleValue}) translateX(-50%)`,
            transformOrigin: '0% 0px',
            left: '50%',
            width: 680,
            height: 963,
          }}
          id="img-leaflet"
          alt={'Đang tải lá số...'}
        />
      </div>

      <Modal
        isOpen={openModal}
        toggle={() => setOpenModal(false)}
        className={''}
        backdrop={true}
      >
        <ModalHeader toggle={() => setOpenModal(false)}>
          Vui lòng nhập thông tin liên hệ
        </ModalHeader>
        <ModalBody>
          <Input
            className="my-2"
            id="phone"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="Số điện thoại"
            type="text"
          />
          <Input
            id="notes"
            name="notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Nội dung yêu cầu"
            type="textarea"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleRequestComment}>
            Gửi
          </Button>{' '}
          <Button color="secondary" onClick={() => setOpenModal(false)}>
            Huỷ
          </Button>
        </ModalFooter>
      </Modal>
      <div className="d-flex justify-content-center">
        {!_.isEmpty(leafNumber) && (
          <div
            style={{
              width: 400,
            }}
            className="d-flex justify-content-between"
          >
            <Button
              onClick={() => {
                downloadImage(
                  (document.getElementById('img-leaflet') as HTMLImageElement)
                    ?.src,
                  'lasotuvi'
                );
              }}
              color="danger"
              className="rounded my-1"
              outline
            >
              Tải lá số
            </Button>{' '}
            <Button
              onClick={() => {
                setOpenModal(true);
              }}
              color="info"
              className="rounded my-1"
              outline
            >
              Gửi yêu cầu bình giải
            </Button>
            <Button
              onClick={() => {
                dispatch(updateLeafNumberInfo(undefined));
                dispatch(updateInfo(undefined));
              }}
              className="rounded my-1"
              outline
            >
              Quay lại
            </Button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default LeafNumber;
