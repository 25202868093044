import { FBSvg, PhoneSvg } from 'assets/svg';
import { colorFiveElements } from 'constant';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

const TopHeader = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className="shadow-sm" style={{ background: '#7f0000' }}>
      <Container fluid="lg py-2">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img
              style={{
                marginRight: 10,
              }}
              width={'50px'}
              alt="..."
              src={require('assets/images/logo.png')}
            />
            {/* <img
              width={'200px'}
              height={'20px'}
              alt="..."
              src={require('assets/images/hh_vn.png')}
            /> */}
            <a
              target="_blank"
              href="https://hocvientuvi.com/"
              style={{
                color: colorFiveElements.tho,
                fontSize: 20,
                fontWeight: '500',
                textDecoration: 'none',
              }}
            >
              Hocvientuvi.com
            </a>
          </div>

          <a
            target="_blank"
            href="https://hocvientuvi.com/"
            style={{
              color: 'white',
              fontSize: 18,
              fontWeight: '500',
              textDecoration: 'underline 2px',
            }}
          >
            Trang chủ
          </a>
          {/* <div
            className={
              'd-flex justify-content-end' +
              (windowDimensions.width < 700
                ? ' flex-column'
                : ' align-items-center')
            }
            style={{ height: '100%' }}
          >
            <div className="d-flex">
              <PhoneSvg />
              <span
                className="mx-2"
                style={{ color: '#7F0000', fontSize: 18, fontWeight: '500' }}
              >
                088 856 08 55
              </span>
            </div>
            <div
              className="mx-3"
              style={{
                width: 2,
                borderRight: '2px solid #7F0000',
                height: '50%',
              }}
            ></div>
            <div className="d-flex">
              <FBSvg />
              <span
                className="mx-2"
                style={{ color: '#7F0000', fontSize: 18, fontWeight: '500' }}
              >
                facebook.com/ThayTuViHaNoi
              </span>
            </div>
          </div> */}
        </div>
      </Container>
    </div>
  );
};

export default TopHeader;
