import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { DataLeafNumber, Info } from 'model';

export interface LeafNumberState {
  leafNumber: DataLeafNumber | undefined;
  info: Info | undefined;
}

const initialState: LeafNumberState = {
  leafNumber: undefined,
  info: undefined,
};

export const leafNumberSlice = createSlice({
  name: 'leafNumber',
  initialState,
  reducers: {
    updateLeafNumberInfo: (
      state,
      action: PayloadAction<DataLeafNumber | undefined>
    ) => {
      state.leafNumber = action.payload;
    },
    updateInfo: (state, action: PayloadAction<Info | undefined>) => {
      state.info = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateLeafNumberInfo, updateInfo } = leafNumberSlice.actions;

export default leafNumberSlice.reducer;
