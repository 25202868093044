import { colorFiveElements } from 'constant';

export interface Info {
  ten: string;
  gioiTinh: number; // 0: nam, 1: nu
  ngaySinh: number;
  thangSinh: number;
  namSinh: number;
  gioSinh: number;
  phutSinh: number;
  namXem: number;
  lichAmDuong: number; // -1: am, 1: duong
  // dienThoai: string;
  thangLap: number; // 0: khong, 1: co, danh cho lunar
  isCreated: boolean;
  isCreatedOnWeb?: boolean;
}

export interface Lich {
  ngayAm: number;
  thangAm: number;
  namAm: number;
  thangLap: number;
  ngayDuong: number;
  thangDuong: number;
  namDuong: number;
  thienCanNam: string;
  diaChiNam: string;
  canChiNam: string;
  canChiThang: string;
  canChiNgay: string;
  canChiGio: string;
}

export interface NamXem {
  nam: number;
  canChiNam: string;
}

export interface AmDuong {
  amDuong: string;
  kieuAmDuong: string;
}
export interface DiaBan {
  lich: Lich;
  namXem: NamXem;
  amDuong: AmDuong;
  banMenh: string;
  cucBanMenh: string;
  chuMenh: string;
  chuThan: string;
}

export interface Tinh {
  MieuDia: number[];
  VuongDia: number[];
  DacDia: number[];
  BinhHoa: number[];
  HamDia: number[];
  ten: string;
  nguHanh: string;
  totXau: number;
  amDuong: number;
}

export interface ThienBan {
  diaChiCung: number;
  thienCanCung: number;
  canChiCung: string;
  cung: string;
  daiVan: number;
  tieuHan: string;
  nguyetHan: string;
  chinhTinh: Tinh[];
  phuTinh: Tinh[];
  saoTrangSinh: string;
  vitri: number;
}
export interface DataLeafNumber {
  diaBan: DiaBan;
  thienBan: ThienBan[];
  triet: number[];
  tuan: number[];
}

//  yin yang
export interface Element {
  code: string;
  name: string;
  color: string;
}

export interface FiveElements {
  Metal: Element;
  Wood: Element;
  Water: Element;
  Fire: Element;
  Earth: Element;
}

const fiveElements: FiveElements = {
  Metal: {
    code: 'K',
    name: 'Kim',
    color: colorFiveElements.kim,
  },
  Wood: {
    code: 'M',
    name: 'Mộc',
    color: colorFiveElements.moc,
  },
  Water: {
    code: 'TH',
    name: 'Thủy',
    color: colorFiveElements.thuy,
  },
  Fire: {
    code: 'H',
    name: 'Hỏa',
    color: colorFiveElements.hoa,
  },
  Earth: {
    code: 'T',
    name: 'Thổ',
    color: colorFiveElements.tho,
  },
};

export interface Acronym {
  name: string;
  acronym: string;
  level: number;
}

export interface Acronyms {
  MieuDia: Acronym;
  VuongDia: Acronym;
  DacDia: Acronym;
  BinhHoa: Acronym;
  HamDia: Acronym;
}

const acronyms: Acronyms = {
  MieuDia: { name: 'Miếu Địa', acronym: 'M', level: 900 },
  VuongDia: { name: 'Vương Địa', acronym: 'V', level: 700 },
  DacDia: { name: 'Đắc Địa', acronym: 'Đ', level: 500 },
  BinhHoa: { name: 'Bình hòa', acronym: 'B', level: 400 },
  HamDia: { name: 'Hãm Địa', acronym: 'H', level: 300 },
};

export interface ResetPasswordBody {
  oldPassword: string;
  newPassword: string;
  newPasswordRetype: string;
}

export default {
  fiveElements,
  acronyms,
};
