import React from 'react';

const ErrorMessage = ({ messageInvalid }: { messageInvalid: string }) => {
  return (
    <div
      style={{
        width: '100%',
        marginTop: '0.25rem',
        fontSize: '80%',
        color: '#dc3545',
      }}
    >
      {messageInvalid}
    </div>
  );
};

export default ErrorMessage;
