import { getLeafNumber } from 'api';
import { DataLeafNumber, Info, ThienBan, Tinh } from 'model';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';

import { RootState } from 'store';
import { scaleTable } from 'utils';
import LeafNumber from './components/LeafNumber';
import { updateInfo, updateLeafNumberInfo } from 'services/slices/leafNumber';
import { useLocation } from 'react-router';

const LSTVMobile = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { leafNumber } = useSelector(
    (state: RootState) => state.leafNumberReducer
  );

  const search = queryString.parse(location.search);

  const [isLoading, setIsLoading] = useState(false);

  const scaleValue = scaleTable({
    currentScreen: { width: window.innerWidth, height: window.innerHeight },
    desire: 0,
  });

  const handleGetLeafNumber = async (values: Info, token = '') => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const response = await getLeafNumber(
        queryString.stringify(values),
        token
      );
      setIsLoading(false);
      if (!!response.data?.message) {
      } else {
        // handle data

        const thienBan: ThienBan[] = !!response.data?.thienBan
          ? response.data.thienBan.map((item: ThienBan): ThienBan => {
              return {
                diaChiCung: item?.diaChiCung ?? 0,
                thienCanCung: item?.thienCanCung ?? 0,
                canChiCung: item?.canChiCung ?? '',
                cung: item?.cung ?? '',
                daiVan: item?.daiVan ?? 0,
                tieuHan: item?.tieuHan ?? '',
                nguyetHan: item?.nguyetHan ?? '',
                chinhTinh:
                  item?.chinhTinh.map((v: Tinh) => ({
                    MieuDia: v?.MieuDia,
                    VuongDia: v?.VuongDia,
                    BinhHoa: v?.BinhHoa,
                    DacDia: v?.DacDia,
                    HamDia: v?.HamDia,
                    ten: v?.ten ?? '',
                    nguHanh: v?.nguHanh ?? '',
                    totXau: v?.totXau,
                    amDuong: v?.amDuong,
                  })) ?? [],
                phuTinh:
                  item?.phuTinh.map((v: Tinh) => ({
                    MieuDia: v?.MieuDia,
                    VuongDia: v?.VuongDia,
                    BinhHoa: v?.BinhHoa,
                    DacDia: v?.DacDia,
                    HamDia: v?.HamDia,
                    ten: v?.ten ?? '',
                    nguHanh: v?.nguHanh ?? '',
                    totXau: v?.totXau,
                    amDuong: v?.amDuong,
                  })) ?? [],
                saoTrangSinh: item?.saoTrangSinh ?? '',
                vitri: item?.vitri,
              };
            })
          : [];
        thienBan.sort((x, y) => x.vitri - y.vitri);

        const data: DataLeafNumber = {
          diaBan: {
            lich: {
              ngayAm: response.data?.diaBan?.lich?.ngayAm ?? 0,
              thangAm: response.data?.diaBan?.lich?.thangAm ?? 0,
              namAm: response.data?.diaBan?.lich?.namAm ?? 0,
              thangLap: response.data?.diaBan?.lich?.thangLap ?? 0,
              ngayDuong: response.data?.diaBan?.lich?.ngayDuong ?? 0,
              thangDuong: response.data?.diaBan?.lich?.thangDuong ?? 0,
              namDuong: response.data?.diaBan?.lich?.namDuong ?? 0,
              thienCanNam: response.data?.diaBan?.lich?.thienCanNam ?? '',
              diaChiNam: response.data?.diaBan?.lich?.diaChiNam ?? '',
              canChiNam: response.data?.diaBan?.lich?.canChiNam ?? '',
              canChiThang: response.data?.diaBan?.lich?.canChiThang ?? '',
              canChiNgay: response.data?.diaBan?.lich?.canChiNgay ?? '',
              canChiGio: response.data?.diaBan?.lich?.canChiGio ?? '',
            },
            namXem: {
              nam: response.data?.diaBan?.namXem?.nam ?? 0,
              canChiNam: response.data?.diaBan?.namXem?.canChiNam ?? '',
            },
            amDuong: {
              amDuong: response.data?.diaBan?.amDuong?.amDuong ?? '',
              kieuAmDuong: response.data?.diaBan?.amDuong?.kieuAmDuong ?? '',
            },
            banMenh: response.data?.diaBan?.banMenh ?? '',
            cucBanMenh: response.data?.diaBan?.cucBanMenh ?? '',
            chuMenh: response.data?.diaBan?.chuMenh ?? '',
            chuThan: response.data?.diaBan?.chuThan ?? '',
          },
          thienBan: thienBan,
          triet: response.data?.triet ?? [],
          tuan: response.data?.tuan ?? [],
        };
        dispatch(updateLeafNumberInfo(data));
        dispatch(updateInfo(values));
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      !!search?.gioSinh &&
      !!search?.gioiTinh &&
      !!search?.lichAmDuong &&
      !!search?.namSinh &&
      !!search?.namXem &&
      !!search?.ngaySinh &&
      !!search?.phutSinh &&
      !!search?.ten &&
      !!search?.thangLap &&
      !!search?.thangSinh
    ) {
      const values: Info = {
        gioSinh: parseInt(search.gioSinh.toString()),
        gioiTinh: parseInt(search.gioiTinh.toString()),
        lichAmDuong: parseInt(search.lichAmDuong.toString()),
        namSinh: parseInt(search.namSinh.toString()),
        namXem: parseInt(search.namXem.toString()),
        ngaySinh: parseInt(search.ngaySinh.toString()),
        phutSinh: parseInt(search.phutSinh.toString()),
        ten: search.ten.toString(),
        thangLap: parseInt(search.thangLap.toString()),
        thangSinh: parseInt(search.thangSinh.toString()),
        isCreated: !!search?.isCreated
          ? /^true$/i.test(search?.isCreated.toString())
          : false,
      };
      const loop = setTimeout(() => {
        handleGetLeafNumber(
          values,
          !!search.token ? search.token.toString() : ''
        );
      }, 500);
      return () => {
        clearTimeout(loop);
      };
    }
  }, [location]);

  return (
    <div className="position-relative">
      <div style={{}} className={'d-flex flex-column justify-content-center'}>
        {!!leafNumber && <LeafNumber scaleValue={scaleValue} />}
      </div>
    </div>
  );
};

export default LSTVMobile;
