import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import HelperText from 'components/HelperText';
import { resetPassword } from 'api';
import TopHeader from 'containers/Home/components/TopHeader';
const LoginPage = () => {
  const history = useHistory();
  const [focusedNewPassword, setFocusedNewPassword] = React.useState(false);
  const [focusedReNewPassword, setFocusedReNewPassword] = React.useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const [errorNewPassword, setErrorNewPassword] = useState('');
  const [errorReNewPassword, setErrorReNewPassword] = useState('');

  const [resetSuccess, setResetSuccess] = useState(false);
  const isNumeric = (value: string) => {
    if (value === '') return true;
    return /^-?\d+$/.test(value);
  };
  const handleChangeNewPassword = (e: any) => {
    if (!isNumeric(e.target.value.trim())) return;
    setNewPassword(e.target.value);
    if (e.target.value === '') {
      setErrorNewPassword('Mật khẩu không được để trống!');
    } else {
      setErrorNewPassword('');
    }
  };

  const handleChangeReNewPassword = (e: any) => {
    if (!isNumeric(e.target.value.trim())) return;
    setReNewPassword(e.target.value);
    if (e.target.value === '') {
      setErrorReNewPassword('Nhập lại mật khẩu không được để trống!');
    } else {
      setErrorReNewPassword('');
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    let errorPassword = false;
    if (newPassword === '') {
      setErrorNewPassword('Mật khẩu không được để trống!');
      errorPassword = true;
    } else if (newPassword.length < 6) {
      setErrorNewPassword('Mật khẩu phải ít nhất 6 số!');
      errorPassword = true;
    }
    if (reNewPassword === '') {
      setErrorReNewPassword('Nhập lại mật khẩu không được để trống!');
      errorPassword = true;
    }
    if (errorPassword) return;
    if (reNewPassword !== newPassword) {
      setErrorReNewPassword('Mật khẩu không khớp. Vui lòng nhập lại!');
      return;
    }
    const query = queryString.stringify({
      token: window.location.href.split('token=')[1],
    });
    const response = await resetPassword(query, { password: newPassword });
    if (!response.data?.message) {
      setResetSuccess(true);
    }
  };

  return (
    <>
      <TopHeader />
      <Container style={{ height: '100%' }} className="mt-5 pb-5">
        <Row className="justify-content-center">
          <Col lg="5" md="8">
            {!resetSuccess ? (
              <>
                <Card className="bg-white border-0 mb-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    <h1 className="text-center text-uppercase">
                      Thay đổi mật khẩu
                    </h1>
                    <Form role="form">
                      <FormGroup
                        className={classnames({
                          focused: focusedNewPassword,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <Input
                            placeholder="Mật khẩu mới"
                            type="password"
                            value={newPassword}
                            onFocus={() => setFocusedNewPassword(true)}
                            onBlur={() => setFocusedNewPassword(false)}
                            onChange={handleChangeNewPassword}
                          />
                        </InputGroup>
                        <HelperText message={errorNewPassword} />
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: focusedReNewPassword,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <Input
                            placeholder="Nhập lại mật khẩu"
                            type="password"
                            value={reNewPassword}
                            onFocus={() => setFocusedReNewPassword(true)}
                            onBlur={() => setFocusedReNewPassword(false)}
                            onChange={handleChangeReNewPassword}
                          />
                        </InputGroup>
                        <HelperText message={errorReNewPassword} />
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          onClick={handleSubmit}
                          className="mt-4"
                          color="info"
                          type="button"
                          outline={false}
                          block={false}
                          style={{
                            color: 'white',
                          }}
                        >
                          Xác nhận
                        </Button>
                        {/* <Button
                      onClick={handleSubmit}
                      className="mt-4"
                      color="info"
                      type="button"
                    >
                      Đặt lại mật khẩu
                    </Button> */}
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                {/* <Row>
                  <Col xs="6">
                    <a className="text-light" href="login">
                      <small>Đăng nhập ?</small>
                    </a>
                  </Col>
                </Row> */}
              </>
            ) : (
              <h1 className="text-center text-uppercase">
                Thay đổi mật khẩu thành công
              </h1>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoginPage;
