import React, { useRef, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from 'reactstrap';
import queryString from 'query-string';
import { Formik } from 'formik';
import * as yup from 'yup';
import _ from 'lodash';

import { getLeafNumber } from 'api';
import { DataLeafNumber, Info, ThienBan, Tinh } from 'model';
import ErrorMessage from 'components/ErrorMessage';
import { days, hours, minutes, months, years } from 'utils';
import { useDispatch } from 'react-redux';
import { updateInfo, updateLeafNumberInfo } from 'services/slices/leafNumber';

const FillInfo = ({
  currentWidth,
  info,
  setInfo,
}: {
  currentWidth: number;
  info: Info;
  setInfo: (value: Info) => void;
}) => {
  const dispatch = useDispatch();

  const infoSchema = yup.object().shape({
    ten: yup.string().required('Tên không được để trống!'),
    ngaySinh: yup
      .number()
      .min(0, 'Must be more than 0')
      .required('Ngày sinh không được để trống!'),
    thangSinh: yup
      .number()
      .min(0, 'Must be more than 0')
      .required('Tháng sinh không được để trống!'),
    namSinh: yup
      .number()
      .min(0, 'Must be more than 0')
      .required('Năm sinh không được để trống!'),
    gioSinh: yup
      .number()
      .min(0, 'Must be more than 0')
      .required('Giờ sinh không được để trống!'),
    phutSinh: yup
      .number()
      .min(0, 'Must be more than 0')
      .required('Phút sinh không được để trống!'),
    namXem: yup
      .number()
      .min(0, 'Must be more than 0')
      .required('Năm xem không được để trống!'),
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (values: Info, actions: any) => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const response = await getLeafNumber(queryString.stringify(values), '');
      setIsLoading(false);
      if (!!response.data?.message) {
      } else {
        // handle data

        const thienBan: ThienBan[] = !!response.data?.thienBan
          ? response.data.thienBan.map((item: ThienBan): ThienBan => {
              return {
                diaChiCung: item?.diaChiCung ?? 0,
                thienCanCung: item?.thienCanCung ?? 0,
                canChiCung: item?.canChiCung ?? '',
                cung: item?.cung ?? '',
                daiVan: item?.daiVan ?? 0,
                tieuHan: item?.tieuHan ?? '',
                nguyetHan: item?.nguyetHan ?? '',
                chinhTinh:
                  item?.chinhTinh.map((v: Tinh) => ({
                    MieuDia: v?.MieuDia,
                    VuongDia: v?.VuongDia,
                    BinhHoa: v?.BinhHoa,
                    DacDia: v?.DacDia,
                    HamDia: v?.HamDia,
                    ten: v?.ten ?? '',
                    nguHanh: v?.nguHanh ?? '',
                    totXau: v?.totXau,
                    amDuong: v?.amDuong,
                  })) ?? [],
                phuTinh:
                  item?.phuTinh.map((v: Tinh) => ({
                    MieuDia: v?.MieuDia,
                    VuongDia: v?.VuongDia,
                    BinhHoa: v?.BinhHoa,
                    DacDia: v?.DacDia,
                    HamDia: v?.HamDia,
                    ten: v?.ten ?? '',
                    nguHanh: v?.nguHanh ?? '',
                    totXau: v?.totXau,
                    amDuong: v?.amDuong,
                  })) ?? [],
                saoTrangSinh: item?.saoTrangSinh ?? '',
                vitri: item?.vitri,
              };
            })
          : [];
        thienBan.sort((x, y) => x.vitri - y.vitri);

        const data: DataLeafNumber = {
          diaBan: {
            lich: {
              ngayAm: response.data?.diaBan?.lich?.ngayAm ?? 0,
              thangAm: response.data?.diaBan?.lich?.thangAm ?? 0,
              namAm: response.data?.diaBan?.lich?.namAm ?? 0,
              thangLap: response.data?.diaBan?.lich?.thangLap ?? 0,
              ngayDuong: response.data?.diaBan?.lich?.ngayDuong ?? 0,
              thangDuong: response.data?.diaBan?.lich?.thangDuong ?? 0,
              namDuong: response.data?.diaBan?.lich?.namDuong ?? 0,
              thienCanNam: response.data?.diaBan?.lich?.thienCanNam ?? '',
              diaChiNam: response.data?.diaBan?.lich?.diaChiNam ?? '',
              canChiNam: response.data?.diaBan?.lich?.canChiNam ?? '',
              canChiThang: response.data?.diaBan?.lich?.canChiThang ?? '',
              canChiNgay: response.data?.diaBan?.lich?.canChiNgay ?? '',
              canChiGio: response.data?.diaBan?.lich?.canChiGio ?? '',
            },
            namXem: {
              nam: response.data?.diaBan?.namXem?.nam ?? 0,
              canChiNam: response.data?.diaBan?.namXem?.canChiNam ?? '',
            },
            amDuong: {
              amDuong: response.data?.diaBan?.amDuong?.amDuong ?? '',
              kieuAmDuong: response.data?.diaBan?.amDuong?.kieuAmDuong ?? '',
            },
            banMenh: response.data?.diaBan?.banMenh ?? '',
            cucBanMenh: response.data?.diaBan?.cucBanMenh ?? '',
            chuMenh: response.data?.diaBan?.chuMenh ?? '',
            chuThan: response.data?.diaBan?.chuThan ?? '',
          },
          thienBan: thienBan,
          triet: response.data?.triet ?? [],
          tuan: response.data?.tuan ?? [],
        };
        dispatch(updateLeafNumberInfo(data));
        dispatch(updateInfo(values));
        setInfo(values);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Container
        style={{ maxWidth: 680 }}
        fluid="sm"
        className="bg-light rounded p-3 shadow-sm"
      >
        <Formik
          initialValues={info}
          enableReinitialize
          onSubmit={onSubmit}
          validationSchema={infoSchema}
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            handleChange,
            handleBlur,
            errors,
            touched,
          }) => {
            return (
              <Form onSubmit={handleSubmit}>
                {/* <p
                  style={{
                    fontSize: 18,
                    fontWeight: '500',
                    fontFamily: 'Inter',
                  }}
                >
                  Nhập thông tin ngày giờ sinh
                </p> */}
                <FormGroup row>
                  <Label for="ten" xs={12} md={2}>
                    Họ tên
                  </Label>
                  <Col xs={12} md={10}>
                    <Input
                      id="ten"
                      name="ten"
                      value={values.ten}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Họ và tên đầy đủ"
                      type="text"
                      invalid={touched.ten && !!errors.ten}
                    />
                    {touched.ten && !!errors.ten && (
                      <ErrorMessage messageInvalid={errors.ten} />
                    )}
                  </Col>
                </FormGroup>
                <table className="my-2">
                  <tr>
                    <th style={{ width: '40%' }}>
                      {currentWidth <= 460 && (
                        <Label for="gender">Giới tính</Label>
                      )}
                    </th>
                    <th>
                      {currentWidth <= 460 && (
                        <Label className="text-center" for="calendar-type">
                          Loại lịch
                        </Label>
                      )}
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <div className="d-flex align-items-center radio-custom">
                        {currentWidth > 460 && (
                          <Label
                            className="mb-0"
                            style={{ marginRight: 55 }}
                            for="gender"
                          >
                            Giới tính
                          </Label>
                        )}
                        <FormGroup
                          onChange={(e) => {
                            const target = e.target as HTMLButtonElement;
                            setFieldValue('gioiTinh', parseInt(target.value));
                          }}
                          className="d-flex"
                        >
                          <FormGroup check className="mx-1">
                            <Input
                              value={0}
                              checked={values.gioiTinh === 0}
                              name="gender"
                              type="radio"
                            />{' '}
                            <Label check>Nam</Label>
                          </FormGroup>
                          <FormGroup check className="mx-1">
                            <Input
                              value={1}
                              checked={values.gioiTinh === 1}
                              name="gender"
                              type="radio"
                            />{' '}
                            <Label check>Nữ</Label>
                          </FormGroup>
                        </FormGroup>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center radio-custom">
                        {currentWidth > 460 && (
                          <Label
                            className="mb-0 "
                            for="calendar-type"
                            style={{ marginLeft: 20 }}
                          >
                            Loại lịch
                          </Label>
                        )}
                        <FormGroup
                          onChange={(e) => {
                            const target = e.target as HTMLButtonElement;
                            setFieldValue(
                              'lichAmDuong',
                              parseInt(target.value)
                            );
                          }}
                          className="d-flex"
                        >
                          <FormGroup check={true} className="mx-1">
                            <Input
                              value={1}
                              checked={values.lichAmDuong === 1}
                              name="lichAmDuong"
                              type="radio"
                            />{' '}
                            <Label check>Dương lịch</Label>
                          </FormGroup>
                          <FormGroup check className="mx-1">
                            <Input
                              value={-1}
                              checked={values.lichAmDuong === -1}
                              name="lichAmDuong"
                              type="radio"
                            />{' '}
                            <Label check>Âm lịch</Label>
                          </FormGroup>
                        </FormGroup>
                      </div>
                    </td>
                  </tr>
                </table>
                <div className="radio-custom">
                  {values.lichAmDuong === -1 && (
                    <FormGroup
                      onChange={(e) => {
                        const target = e.target as HTMLInputElement;
                        setFieldValue('thangLap', target.checked ? 1 : 0);
                      }}
                      className={'d-flex'}
                    >
                      <Label style={{ marginRight: 10 }} for="thangLap">
                        Tháng Lặp
                      </Label>
                      <FormGroup check>
                        <Input
                          checked={values.thangLap === 1}
                          id="thangLap"
                          type="checkbox"
                        />
                      </FormGroup>
                    </FormGroup>
                  )}
                </div>

                <FormGroup row>
                  <Label for="ngaySinh" md={2}>
                    {currentWidth <= 460 ? 'Ngày/Tháng/Năm sinh' : 'Ngày sinh'}
                  </Label>
                  <Col md={10}>
                    <div className="d-flex">
                      <Input
                        id="ngaySinh"
                        name="ngaySinh"
                        style={{
                          maxWidth: 200,
                          marginRight: 10,
                          fontSize: 13.5,
                        }}
                        type="select"
                        value={values.ngaySinh}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        invalid={touched.ngaySinh && !!errors.ngaySinh}
                      >
                        <option value={-1}>Ngày sinh</option>
                        {days.map((item: { id: number; name: string }) => {
                          return (
                            <option value={item.id} key={item.id}>
                              Ngày {item.name}
                            </option>
                          );
                        })}
                      </Input>
                      <Input
                        id="thangSinh"
                        name="thangSinh"
                        style={{
                          maxWidth: 200,
                          marginRight: 10,
                          fontSize: 13.5,
                        }}
                        type="select"
                        value={values.thangSinh}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        invalid={touched.thangSinh && !!errors.thangSinh}
                      >
                        <option value={-1}>Tháng sinh</option>
                        {months.map((item: { id: number; name: string }) => {
                          return (
                            <option value={item.id} key={item.id}>
                              Tháng {item.name}
                            </option>
                          );
                        })}
                      </Input>
                      <Input
                        id="namSinh"
                        name="namSinh"
                        style={{ maxWidth: 200, fontSize: 13.5 }}
                        type="select"
                        value={values.namSinh}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        invalid={touched.namSinh && !!errors.namSinh}
                      >
                        <option value={-1}>Năm sinh</option>
                        {years.map((item: { id: number; name: string }) => {
                          return (
                            <option value={item.id} key={item.id}>
                              Năm {item.name}
                            </option>
                          );
                        })}
                      </Input>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="gioSinh" md={2}>
                    Giờ sinh
                  </Label>
                  <Col md={10}>
                    <div className="d-flex">
                      <Input
                        id="gioSinh"
                        name="gioSinh"
                        style={{
                          maxWidth: 200,
                          marginRight: 10,
                          fontSize: 13.5,
                        }}
                        type="select"
                        value={values.gioSinh}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        invalid={touched.gioSinh && !!errors.gioSinh}
                      >
                        <option value={-1}>Giờ sinh</option>
                        {hours.map((item: { id: number; name: string }) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Input>
                      <Input
                        id="phutSinh"
                        name="phutSinh"
                        style={{ maxWidth: 200, fontSize: 13.5 }}
                        type="select"
                        value={values.phutSinh}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        invalid={touched.phutSinh && !!errors.phutSinh}
                      >
                        <option value={-1}>Phút sinh</option>
                        {minutes.map((item: { id: number; name: string }) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          );
                        })}
                      </Input>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label for="namXem" md={2}>
                    Năm xem
                  </Label>
                  <Col md={10}>
                    <Input
                      id="namXem"
                      name="namXem"
                      placeholder="Năm"
                      type="text"
                      style={{ maxWidth: 200 }}
                      value={values.namXem <= -1 ? '' : values.namXem}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      invalid={touched.namXem && !!errors.namXem}
                    />
                    {touched.namXem && !!errors.namXem && (
                      <ErrorMessage messageInvalid={errors.namXem} />
                    )}
                  </Col>
                </FormGroup>
                <div className="d-flex justify-content-center">
                  <Button disabled={isLoading} type="submit" color="danger">
                    {isLoading && <Spinner color="warning" size="sm"></Spinner>}{' '}
                    LẬP LÁ SỐ TỬ VI
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Container>
    </div>
  );
};

export default FillInfo;
