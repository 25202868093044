import React from 'react';

const Banner = () => {
  return (
    <div className="d-flex flex-column align-items-center mt-3">
      <p
        style={{
          color: '#7f0000',
          fontSize: 24,
          fontWeight: '500',
          fontFamily: 'Inter',
        }}
        className="mb-0 text-uppercase"
      >
        An sao tử vi
      </p>
      {/* <img
        width={'160px'}
        alt="..."
        src={require('assets/images/under_title.png')}
      /> */}
    </div>
  );
};

export default Banner;
