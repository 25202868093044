export const colorFiveElements = {
  kim: '#736E72',
  moc: '#2c8311',
  thuy: '#000000',
  hoa: '#e60000',
  tho: '#ffad00',
};

export const zodiac = [
  { color: colorFiveElements.thuy, label: 'Tý' },
  { color: colorFiveElements.tho, label: 'Sửu' },
  { color: colorFiveElements.moc, label: 'Dần' },
  { color: colorFiveElements.moc, label: 'Mão' },
  { color: colorFiveElements.tho, label: 'Thìn' },
  { color: colorFiveElements.hoa, label: 'Tỵ' },
  { color: colorFiveElements.hoa, label: 'Ngọ' },
  { color: colorFiveElements.tho, label: 'Mùi' },
  { color: colorFiveElements.kim, label: 'Thân' },
  { color: colorFiveElements.kim, label: 'Dậu' },
  { color: colorFiveElements.tho, label: 'Tuất' },
  { color: colorFiveElements.thuy, label: 'Hợi' },
];

export const topStars = [
  // SatTinh
  { value: 1, label: 'Hóa Kỵ' },
  { value: 2, label: 'Địa Không' },
  { value: 3, label: 'Địa Kiếp' },
  { value: 4, label: 'Kình Dương' },
  { value: 5, label: 'Đà La' },
  { value: 6, label: 'Hỏa Tinh' },
  { value: 7, label: 'Linh Tinh' },
  { value: 8, label: 'Thiên Không' },
  { value: 9, label: 'Đại Hao' },
  { value: 10, label: 'Thiên Hình' },
  // CatTinh
  { value: 11, label: 'Hóa Lộc' },
  { value: 12, label: 'Hóa Quyền' },
  { value: 13, label: 'Hóa Khoa' },
  { value: 14, label: 'Thiên Khôi' },
  { value: 15, label: 'Thiên Việt' },
  { value: 16, label: 'Tả Phụ' },
  { value: 17, label: 'Hữu Bật' },
  { value: 18, label: 'Văn Xương' },
  { value: 19, label: 'Văn Khúc' },
  { value: 20, label: 'Thiên Mã' },
  { value: 21, label: 'Lộc Tồn' },
  { value: 22, label: 'Đào Hoa' },
  { value: 23, label: 'Hồng Loan' },
  { value: 24, label: 'Thái Tuế' },
  // other
  { value: 25, label: 'Tiểu Hao' },
  { value: 26, label: 'Phục Binh' },
  { value: 27, label: 'Phi Liêm' },
  { value: 28, label: 'Bạch Hổ' },
  { value: 29, label: 'Tang Môn' },
  { value: 30, label: 'Cô Thần' },
  { value: 31, label: 'Quả Tú' },
  { value: 32, label: 'Kiếp Sát' },
  { value: 33, label: 'Điếu Khách' },
  { value: 34, label: 'Thiên Khốc' },
  { value: 35, label: 'Thiên Hư' },
  { value: 36, label: 'Hoa Cái' },
  { value: 37, label: 'Long Trì' },
  { value: 38, label: 'Phượng Các' },
  { value: 39, label: 'Tam Thai' },
  { value: 40, label: 'Bát Toạ' },
  { value: 41, label: 'Thai Phụ' },
  { value: 42, label: 'Ân Quang' },
  { value: 43, label: 'Thiên Quý' },
  { value: 44, label: 'Đẩu Quân' },
  { value: 45, label: 'Tướng Quân' },
  { value: 46, label: 'Thanh Long' },
  { value: 47, label: 'Quốc Ấn' },
  { value: 48, label: 'Tấu Thư' },
  { value: 49, label: 'Giải Thần' },
  { value: 50, label: 'Thiên Hỷ' },
  { value: 51, label: 'Hỷ Thần' },
  { value: 52, label: 'Lưu Hà' },
  { value: 53, label: 'Hỷ Thần' },
  { value: 54, label: 'Thiên Diêu' },
  { value: 55, label: 'Thiên Y' },
  { value: 56, label: 'Thiên Trù' },
  { value: 57, label: 'Thiên Phúc' },
  { value: 58, label: 'Thiên Đức' },
  { value: 59, label: 'Phúc Đức' },
  { value: 60, label: 'Nguyệt Đức' },
  { value: 61, label: 'Thiên Quan' },
  { value: 62, label: 'Thiên Giải' },
  { value: 63, label: 'Thiên Quý' },
  { value: 64, label: 'Quan Phủ' },
  { value: 65, label: 'Bệnh Phù' },
  { value: 66, label: 'Trực Phù' },
  { value: 67, label: 'Phong Cáo' },
  { value: 68, label: 'Long Đức' },
  { value: 69, label: 'Tuế Phá' },
  { value: 70, label: 'Phá Toái' },
  { value: 71, label: 'Đường Phù' },
];

export const bottomStars = [
  { value: 101, label: 'Thiên La' },
  { value: 102, label: 'Địa Võng' },
  { value: 103, label: 'Thiếu Dương' },
  { value: 104, label: 'Thiếu âm' },
  { value: 105, label: 'Bác Sĩ' },
  { value: 106, label: 'Thiên Tài' },
  { value: 106, label: 'Thiên Thọ' },
  { value: 106, label: 'LN. Văn Tinh' },
  { value: 106, label: 'Thiên Sứ' },
  { value: 199, label: 'L.' },
];
