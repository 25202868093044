import React from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  Redirect,
} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import Home from 'containers/Home';
import ResetPassword from 'containers/ResetPassword';
import LSTVMobile from 'containers/LSTVMobile';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} exact={true} />
        <Route path="/lasotuvi-mobile" component={LSTVMobile} exact={true} />
        <Route
          path="/auth/reset-password"
          component={ResetPassword}
          exact={true}
        />

        <Redirect from="*" to={'/'} />
      </Switch>
    </Router>
  );
}

export default App;
