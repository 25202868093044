import { RefObject, useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';

import { topStars, bottomStars } from 'constant';
import YinYang, { Acronyms, Tinh } from 'model';

export const days = Array.apply(null, Array(31)).map(
  (__: any, index: number) => {
    return { id: index + 1, name: `${index + 1}` };
  }
);
export const months = Array.apply(null, Array(12)).map(
  (__: any, index: number) => {
    return { id: index + 1, name: `${index + 1}` };
  }
);
export const years = Array.apply(null, Array(2099 - 1899)).map(
  (__: any, index: number) => {
    return {
      id: 2099 - index,
      name: `${2099 - index}`,
    };
  }
);

export const hours = Array.apply(null, Array(24)).map(
  (__: any, index: number) => {
    return { id: index, name: `${index} Giờ` };
  }
);

export const minutes = Array.apply(null, Array(60)).map(
  (__: any, index: number) => {
    return { id: index, name: `${index} Phút` };
  }
);

export const gender = ['Nam', 'Nữ'];

const screenWidth = 680;
const screenHeight = 963;
const width = 1920;

export const scaleTable = ({
  currentScreen,
  desire = 0,
}: {
  currentScreen: { width: number; height: number };
  desire?: number;
}) => {
  if (desire === 0) {
    const scale = currentScreen.width / screenWidth;
    if (scale <= 1) {
      return scale - 0.05;
    } else {
      return 0.75;
    }
  } else if (desire === 1) {
    const scale = currentScreen.height / screenHeight;
    if (scale <= 1) {
      return scale;
    }
  }
  return 1;
};

export const handleResidenceInThePalace = (item: Tinh, position: number) => {
  try {
    let valid: any = null;
    topStars.every((v) => {
      if (item.ten.trim().toLowerCase() === v.label.toLowerCase()) {
        valid = v;
        return false;
      }
      return true;
    });

    for (const key in item) {
      const residenceInThePalace = item[key as keyof Tinh];
      if (
        Array.isArray(residenceInThePalace) &&
        residenceInThePalace.includes(position)
      ) {
        const acronym = YinYang.acronyms[key as keyof Acronyms];
        if (_.isEmpty(valid)) {
          bottomStars.every((v) => {
            if (item.ten.trim().toLowerCase().includes(v.label.toLowerCase())) {
              valid = v;
              return false;
            }
            return true;
          });
          if (!_.isEmpty(valid))
            return {
              code: key,
              acronym: acronym?.acronym ?? '',
              fontWeight: '400',
              level: !_.isEmpty(valid) ? valid.value : 90,
            };
        }
        return {
          code: key,
          acronym: acronym?.acronym ?? '',
          fontWeight: !_.isEmpty(valid) && valid.value <= 24 ? '900' : '400',
          level: !_.isEmpty(valid) ? valid.value : 90,
        };
      }
    }
    if (_.isEmpty(valid)) {
      bottomStars.every((v) => {
        if (item.ten.trim().toLowerCase().includes(v.label.toLowerCase())) {
          valid = v;
          return false;
        }
        return true;
      });
      if (!_.isEmpty(valid))
        return {
          code: '',
          acronym: '',
          fontWeight: '400',
          level: !_.isEmpty(valid) ? valid.value : 90,
        };
    }
    return {
      code: '',
      acronym: '',
      fontWeight: !_.isEmpty(valid) && valid.value <= 24 ? '900' : '400',
      level: !_.isEmpty(valid) ? valid.value : 90,
    };
  } catch (error) {
    return null;
  }
};

export const positionMenhTaiQuanDi = (position: number) => {
  // x: 170, y: 240
  const x = 340;
  const y = 480;
  switch (position) {
    case 0:
      return { x: (3 * x) / 4, y: y };
    case 1:
      return { x: x / 4, y: y };
    case 2:
      return { x: 0, y: y };
    case 3:
      return { x: 0, y: (y * 3) / 4 };
    case 4:
      return { x: 0, y: y / 4 };
    case 5: // goc
      return { x: 0, y: 0 };
    case 6:
      return { x: x / 4, y: 0 };
    case 7:
      return { x: (3 * x) / 4, y: 0 };
    case 8:
      return { x: x, y: 0 };
    case 9:
      return { x: x, y: y / 4 };
    case 10:
      return { x: x, y: (3 * y) / 4 };
    case 11:
      return { x: x, y: y };
    default:
      return { x: 0, y: 0 };
  }
};

export function useDidUpdateEffect(fn: () => void, inputs: any) {
  const fncRef: any = useRef();
  fncRef.current = fn;
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      return fncRef.current();
    }
  }, inputs);
}

export default function useOnScreen(ref: RefObject<any>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    [ref]
  );

  useEffect(() => {
    if (!ref?.current) return;
    observer.observe(ref?.current);
    return () => observer.disconnect();
  }, []);

  return isIntersecting;
}
